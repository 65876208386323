import React from "react";
import "./ArticleSectionNavigation.scss";
import { motion } from "framer-motion";
import { SectionLink } from "react-scroll-section";
import { Link } from "gatsby";
import { window, exists } from "browser-monads";

const ArticleSectionNavigation = React.memo(
    ({ items, language, renewCookiePolicy, cookiePolicyLabel }) => {
        exists(window);

        const animateSectionNavigation = {
            initial: {
                opacity: 0,
            },

            enter: {
                opacity: 1,
                transition: {
                    when: "beforeChildren",
                    staggerChildren: 0.1,
                    delay: 0.3,
                },
            },
        };

        const animateSectionItems = {
            initial: {
                y: 20,
                opacity: 0,
            },

            enter: {
                y: 0,
                opacity: 1,
                transition: {
                    type: "spring",
                    ease: "easeIn",
                    damping: 50,
                },
            },
        };

        return (
            <div className="article-section">
                <motion.ul
                    variants={animateSectionNavigation}
                    initial="initial"
                    animate="enter"
                    className="article-section__navigation"
                >
                    <>
                        {items.map((section, key) =>
                            !section.link ? (
                                <SectionLink
                                    key={key}
                                    section={`section-${key}`}
                                >
                                    {(link) =>
                                        section.title && (
                                            <motion.li
                                                variants={animateSectionItems}
                                                onClick={link.onClick}
                                                className={
                                                    link.isSelected
                                                        ? "is-current"
                                                        : ""
                                                }
                                            >
                                                <p>{section.title}</p>
                                            </motion.li>
                                        )
                                    }
                                </SectionLink>
                            ) : (
                                <motion.li
                                    key={key}
                                    variants={animateSectionItems}
                                >
                                    <Link
                                        to={`/${language}/${section.link.slug}`}
                                    >
                                        {section.link.menuTitle}
                                    </Link>
                                </motion.li>
                            )
                        )}
                        {renewCookiePolicy && (
                            <motion.li variants={animateSectionItems}>
                                <button
                                    onClick={() => {
                                        if (window.CookieConsent) {
                                            window.CookieConsent.renew();
                                        }
                                    }}
                                    className=""
                                >
                                    {cookiePolicyLabel.cookiePolicyLabel}
                                </button>
                            </motion.li>
                        )}
                    </>
                </motion.ul>
            </div>
        );
    }
);

export default ArticleSectionNavigation;
